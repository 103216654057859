/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  src: url("./assets/fonts/Poppins-Black.ttf");
}

@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: normal;
  src: url("./assets/fonts/Poppins-BlackItalic.ttf");
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: bold;
  src: url("./assets/fonts/Poppins-Bold.ttf");
}

@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: bold;
  src: url("./assets/fonts/Poppins-BoldItalic.ttf");
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: bolder;
  src: url("./assets/fonts/Poppins-ExtraBold.ttf");
}

@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: bolder;
  src: url("./assets/fonts/Poppins-ExtraBoldItalic.ttf");
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: lighter;
  src: url("./assets/fonts/Poppins-ExtraLight.ttf");
}

@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: lighter;
  src: url("./assets/fonts/Poppins-ExtraLightItalic.ttf");
}

@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: normal;
  src: url("./assets/fonts/Poppins-Black.ttf");
}

@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: normal;
  src: url("./assets/fonts/Poppins-Italic.ttf");
}

@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: normal;
  src: url("./assets/fonts/Poppins-LightItalic.ttf");
}

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

* {
  font-family: "Poppins" !important;
  color: #f7f2f2;
}

.ad-box{
  margin: 10px 0; 
  padding: 5px;
  margin-top:3px;
}

.sb-content-container{
  margin-top: 70px !important;
  max-width: 380px !important;
  display:block;
}
.sb-highlight-bg{
  background: #53086D;
  padding: 5px;
}

.sb-funbay-button{
  position: fixed;
  bottom: 2px;
  width: 100%;
  margin: auto;
}

.spotbay-bg{
  background-color: #30023F !important;
}

.spotbay-gradient-border {
  border: 1px solid !important;
  border-image: linear-gradient(266.27deg, #049CF2 42.06%, #FF02E4 84.33%, #F804FF 132.8%) 1 !important;
}

.spotbay-gradient-border-tb {
  border: 1px solid !important;
  border-image: linear-gradient(0deg, #049CF2 42.06%, #FF02E4 84.33%, #F804FF 132.8%) 1 !important;
}

.spotbay-sub-title{
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #FEDB77;
}

.spotbay-main-title{
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  color: #FEDB77;
}

.spotbay-input {
  width: 90%;
  height: 3em;
  /*border-radius: 25px;*/
  background-image: linear-gradient(#2f0743 0%, #2f0743 0%),
    radial-gradient(circle at top left, #ff02e4, #f804ff, #049cf2);
  background-origin: border-box;
  background-clip: content-box, border-box;
  // box-shadow: 2px 2px 7px #c205ff;
  border: 1px transparent;
  padding: 1px;
  text-align: center;
  color: #fff;
}
.spotbay-input-labels{
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 27px;
  color: #F8FFFF;
}
.spotbay-button {
  background: #30023f;
  /*border-radius: 20px;*/
  height: 40px;
  width: 30%;
  background-image: linear-gradient(#2f0743 0%, #2f0743 0%),
    radial-gradient(circle at top left, #ff02e4, #f804ff, #049cf2);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 0px 0px 7px #c205ff;
  // border: double 1px transparent;
  padding: 2px;
  text-align: center;
  margin: 23px;
  font-weight: bold;
  font-size: 18px;
}
.spotbay-button:active {
  transform: scale(0.98);
  box-shadow: 0px 0px 26px 3px #c205ff !important;
  background: #f804ff;
}

ion-card{
  background: #30023f;
  background-image: linear-gradient(#2f0743 0%, #2f0743 0%),
    radial-gradient(circle at top left, #ff02e4, #f804ff, #049cf2);
  background-origin: border-box;
  background-clip: content-box, border-box;
  text-align: center;
  margin: 23px;
  font-weight: bold;
  font-size: 18px;
  width: auto;
  height: 14.375rem !important;
  border-radius: 0px !important;
  border:none;
  // box-shadow: 0px 0px 25px #c205ff !important;
  padding: 1px !important;
}

// .img_back{
//   height: 20vh;
//   width: 100%;
//   // display: block;
//   background-size: cover;
//   background-repeat: no-repeat;
// }

ion-button{
  background: #30023f;
  background-image: linear-gradient(#2f0743 0%, #2f0743 0%),
    radial-gradient(circle at top left, #ff02e4, #f804ff, #049cf2);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 0px 0px 7px #c205ff;
  text-align: center;
  margin: 23px;
  font-weight: bold;
  border-radius: 0px !important;
  border:none;
  padding: 1px !important;
  font-size: 1rem !important;
  height: 2.6rem;
  width: 7.5rem;
}
ion-button::part(native) {
  background: transparent !important;
}
ion-button::part(native):active {
  transform: scale(0.98);
  box-shadow: 0px 0px 26px 3px #c205ff !important;
}
ion-header{
  box-shadow: 0px 0px 7px #c205ff;
}

.spotbay-margin-top-5{
  margin-top: 5px
}
.spotbay-margin-left-5{
  margin-left: 5px
}

.info{
  color:#ffffff;
  width: 30px;
  height: 25px;
}

.game-console{
  color:#B788FF;
  size: 14px;
}

.header-logo{
  position: absolute;
  left: 6.12%;
  right: 72.11%;
  top: 2.92%;
  bottom: 93.63%;
  
  background: url(./assets/spotbay-images/spotbay-logo.svg);
  background-repeat: no-repeat !important;
  height: 32px;
  width: 96px;
}

.balls-bg-img{
  position: absolute;
  width: 308px;
  height: 197px;
  max-width: 500px;
  
  left: 25%;
  top: 10%;
  background: url(./assets/spotbay-images/login-background-balls.svg);
  background-repeat: no-repeat !important;
}

.glass-bg{
  /*background: rgba( 229, 189, 189, 0.1 );*/
  background: absolute-gradient(180deg, rgba(237, 234, 234, 0.1044) 0%, rgba(237, 234, 234, 0.036) 100%);
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 0px );
  -webkit-backdrop-filter: blur( 0px );
  /*border-radius: 10px;*/
  border: 1px solid rgba( 255, 255, 255, 0.18 );
}

.loading-message{
  text-align: center;
  font-weight: 400;
  font-size: 25px;
  margin: 0px;
}

.icon-right-top{
  position: absolute;
  left: 72.11%;
  right:6.12%;
  top: 2.92%;
  bottom: 93.63%;
  background-repeat: no-repeat !important;
  height: 33px;
  width: 96px;
}

.glossy-input {
	width:95%;
	// needs to be relative so the :focus span is positioned correctly
	position:relative;
	
	// bigger font size for demo purposes
	font-size: 1.5em;
	
	// the border gradient
	//background: linear-gradient(to right bottom, rgba(152, 4, 242, 1), rgba(248, 4, 255, 1));
	//border: 1px solid;

  background: linear-gradient(266.27deg, #049CF2 2.33%, #FF02E4 44.6%, #F804FF 93.07%);


	// the width of the input border
	padding: 1px;
	
	// we want inline fields by default
	display: inline-block;
	
	// we want rounded corners no matter the size of the field
	//border-radius: 9999em;
	
	// style of the actual input field
	*:not(span) {
        width:100%;
		position: relative;
		display: inherit;
		border-radius: inherit;
		margin: 0;
		border: none;
		outline: none;
		padding: 0 .325em;
		z-index: 1; // needs to be above the :focus span
		background: rgb(66, 4, 105);
        border-color: transparent;
        color:#fff;
		// summon fancy shadow styles when focussed
		&:focus + span {
			opacity: 1;
			transform: scale(1);
            border-color: transparent;
            outline: none;
            border: none;
		}
	}
	
	// we don't animate box-shadow directly as that can't be done on the GPU, only animate opacity and transform for high performance animations.
	span {
		
		transform: scale(.993, .94); // scale it down just a little bit
		transition: transform .5s, opacity .25s;
		opacity: 0; // is hidden by default
		
		position:absolute;
		z-index: 0; // needs to be below the field (would block input otherwise)
		margin:4px; // a bit bigger than .input padding, this prevents background color pixels shining through
		left:0;
		top:0;
		right:0;
		bottom:0;
		border-radius: inherit;
		pointer-events: none; // this allows the user to click through this element, as the shadow is rather wide it might overlap with other fields and we don't want to block those.
		
		// fancy shadow styles
		box-shadow: inset 0 0 0 3px rgb(66, 4, 105),
			0 0 0 4px #F804FF,
			3px -3px 30px #049CF2, 
			-3px 3px 30px #FF02E4;
	}
	
}

.error-message{
  color: red !important; 
  background-color: rgb(255 255 255) !important;
  text-align: left;padding: 3px !important;
  margin-top: 3px !important;
  width: 100% !important;
}

.blink-text {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: .5;
  }
}

.contest-container-div {
  width: 90%;
  height: 33vh; 
  margin: 20px ;
  position: relative;
  display: flex;
  justify-content: center;
  align-self: center;
  
}

.contest-picture-div {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 8px;
}
.contest-logo-container{
  position: absolute; 
  margin-top: 160px; 
  right: 30px;
  img{
    max-height: 50px;
  }
}

.contest-play-button-container{
  // height: 160px; 
  text-align: center; 
  vertical-align: middle;
}

.contest-div-pattern{
  text-align: center;
  height: 224px;
  width: 384px;
  background-repeat: no-repeat;
  background-position: unset;
  background-size: unset;
  position: absolute;
  overflow: hidden;
  // margin-top: -227px; 
}

.funbay-poster{
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 224px;
  position: absolute;
  width: 350px;
  padding:2px;
}

.funbay-headline{
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 224px;
  position: absolute;
  //width: 384px;  
  font-size: x-large;
  font-weight: 600;
  padding:20px;
  opacity: 0;
}